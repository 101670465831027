import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

const Page404 = () => (
    <div>
        <Helmet>
            <title>404</title>
        </Helmet>
        <h1>404 - page not found :(</h1>
    Sorry about that, how about you go <Link to="/">home</Link>.
    </div>
);

export default Page404;
